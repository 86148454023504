/* eslint-disable react/display-name */
import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'src/containers/Link';
import { Section, SectionHeader } from 'src/components/molecules/Section';
import { Grid } from 'src/components/molecules/Grid';
import { Card } from 'src/components/atoms/Card';
import { FluidImg } from 'src/embeds/image/image-dato';

function createColumns(n) {
  return Array(n).fill('1fr').join(' ');
}

const CalloutHeader = styled.h3`
  ${({ customTextColor }) => customTextColor && css`
    color: ${customTextColor}
  `}
  margin-bottom: 0.7rem;
`

const preHeadingStyles = css`
  font-size: ${({ theme }) => theme.smallFontSize};
  margin-bottom: 0.7rem;
`

const groupMargin = css`
  margin-bottom: 1rem;
`
const cardStyles = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  word-break: keep-all;
  hyphens: none;
  white-space: pre-wrap;
`

const dateLocationStyle = css`
  font-size: ${({ theme }) => theme.smallFontSize};
  margin-bottom: 0.7rem;
  color: var(--primary-color, #5a17ee);
  font-weight: bold;
`

export default function Drawer({
  heading,
  content,
  contentNode,
  callouts,
  customData,
  backgroundImage,
}) {
  const drawerCustomData = JSON.parse(customData);
  const upcomingEvents = callouts.filter(callout => callout.__typename == 'DatoCmsUpcomingEvent');

  return (
    <Section
      {...drawerCustomData?.flags}
      backgroundImage={
        !!backgroundImage && (
          <FluidImg pictureStyle={{ objectFit: 'cover' }} maxWidth={null} data={backgroundImage} />
        )
      }
    >
      <SectionHeader>
        {heading &&<h2>{heading}</h2>}
        {content && <div dangerouslySetInnerHTML={{ __html: contentNode.childMarkdownRemark.html }} />}
      </SectionHeader>
      {upcomingEvents.length > 0 && (
        <Grid columns={createColumns(drawerCustomData?.columnCount ?? upcomingEvents.length)}>
          {upcomingEvents.map(event => {
            const {
              description,
              id,
              image,
              title,
              ctaAnchorText,
              displayCategory,
              ctaLink,
              date,
              location
            } = event;

            const dateLocationSeparator = date && location ? ' | ' : '';
            const locationFormatted = location || '';
            /**
             * Arg format for date string has to be in
             * YYYY/MM/DD to work correctly i.e not be behind 1 day
             */
            const eventDateObj = date ? new Date(date.replace(/-/g, '\/')) : undefined;

            /** .toDateString to remove time from today's date */
            const today = new Date(new Date().toDateString());
            const eventDateDifference = eventDateObj ? (eventDateObj - today) : 1;
            /** Add case if event date is today */
            const dateFormatted = eventDateDifference === 0
              ? 'Today'
              : eventDateObj
              ? eventDateObj.toLocaleDateString('en-US', {
                year: "numeric",
                month: "2-digit",
                day: "2-digit"
              })
              : '';

            /**
             * If event doesn't have a date show event anyways
             */

            if (eventDateDifference < 0) {
              return;
            }

            const dateLocation = `${dateFormatted}${dateLocationSeparator}${locationFormatted}`;

            return (
              <Card key={id} css={cardStyles} noPadding>
                <div>
                  {image && <FluidImg
                    data={image}
                    width={image.width || image.height} />
                  }
                  <div css={css`
                    padding: 1.5rem 2rem;
                  `}>
                    {displayCategory && <div css={preHeadingStyles}>{displayCategory}</div>}
                    <CalloutHeader>{title}</CalloutHeader>
                    <p css={dateLocationStyle}>
                      {dateLocation}
                    </p>
                    <div css={groupMargin}>
                      {description}
                    </div>
                  </div>
                </div>
                <div css={css`
                  padding: 2rem;
                `}>
                  <Link to={ctaLink}>
                    {ctaAnchorText} →
                  </Link>
                </div>
              </Card>
            );
          })}
        </Grid>
      )}
    </Section>
  );
}
